
import { defineComponent } from "vue-demi";
import { PAYMENT_DETAILS } from "@/store/common/user";
import { PenIcon, CheckmarkIcon } from '@/plugins/icons';
import { useAPI } from "@/use";
import { dynamicsObject } from "@/interfaces";
import { Channel } from "@/interfaces/channel/channel.dto";

export default defineComponent({
  name: "ModalPaymentList",
  props: {
    input: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      payments: PAYMENT_DETAILS,
    };
  },
  methods: {
    async togglePayment(payment: string) {
      this.data.payment[payment] = !this.data.payment[payment];
      const options = { _id: this.data.channel } as dynamicsObject;
      options[`payment__${payment}`] = this.data.payment[payment];

      await useAPI().channel.togglePaymentMethod(options);
    },
    router(payment: string) {
      this.$router.push(`/payment/details?payment=${payment}`);
    },
    isFilledPayment(payment: keyof Channel.Dto['payment']) {
      const fields = {
        yoomoney: ['purse', 'secretKey'],
        tinkoff: ['terminal', 'password'],
        robokassa: ['identificator', 'first_password', 'second_password'],
        prodamus: ['secretKey', 'src'],
        paykassa: ['identificator', 'password'],
        botpay: [],
      }
      const currentPayment = this.$store.getters.user.payment[payment];
      const currentField = fields[payment];
      
      for (const field of currentField)
        if (!currentPayment[field]) return false;

      return true;
    }
  },
  components: {
    PenIcon,
    CheckmarkIcon
  }
});
