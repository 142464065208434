import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fce35a6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "payment-list flex-column" }
const _hoisted_2 = { class: "payment--icon" }
const _hoisted_3 = { class: "payment--name fz16 fw500" }
const _hoisted_4 = { class: "payment--action flex items-center" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pen_icon = _resolveComponent("pen-icon")!
  const _component_checkmark_icon = _resolveComponent("checkmark-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payments, (payment) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["payment flex-center-between", [payment.id, { detail: _ctx.data.payment[payment.id] }]]),
        key: payment.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(payment.icon), {
            selected: _ctx.data.payment[payment.id]
          }, null, 8, ["selected"]))
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(payment.name), 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_pen_icon, {
            class: "cursor",
            color: _ctx.data.payment[payment.id] ? '#fff' : '#000',
            onClick: ($event: any) => (_ctx.router(payment.id))
          }, null, 8, ["color", "onClick"]),
          (_ctx.isFilledPayment(payment.id))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["toggle flex-center cursor", { active: _ctx.data.payment[payment.id] }]),
                onClick: ($event: any) => (_ctx.togglePayment(payment.id))
              }, [
                (_ctx.data.payment[payment.id])
                  ? (_openBlock(), _createBlock(_component_checkmark_icon, { key: 0 }))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_5))
            : _createCommentVNode("", true)
        ])
      ], 2))
    }), 128))
  ]))
}